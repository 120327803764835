
@use "2024/bootstrap/bootstrap-reboot";
@use "2024/bootstrap/bootstrap-grid";

@use "common";

@use "speaker-page-2015";

@use "corner-ribbon";


$cdn_nfjs: 'https://cdn.nofluffjuststuff.com';

$orange: #F7941D;
$yellow: #F2C752;

a { text-decoration: none; }

//input, textarea, select, button { font-size: 16px; }

body.show-main div.container.body { min-height: 500px; }

body.show-home {

    div.nextyear {
        text-align: center;

    }

    div.sponsors {
        background: #f1f1f1;
        border-bottom: 1px solid #999;
        text-align: center;
        a { display: block; margin: 0 auto; }
        img { max-width: 100%; }
        a.thoughtworks { margin: 30px auto 0 auto; }
        a.gradleware { margin: 10px auto 0 auto; }
        a.homeadvisor { margin: 20px auto 0 auto; }
    }

    div.sold-out {
        text-align: center;
        margin: 0 0 20px 0;
        h3 { color: #CC0000; }
    }

    div.sms-bar {

        background: #F1F1F1;
        p { font-size: 12px; }
        button { margin-top: -3px; background: $orange; color: #fff; }

    }

    div.virtual-workshop-credits {
      text-align: center;

      h3 {
        margin-bottom: 20px;
      }
    }

    div.sponsor-workshop  {
      background: #f1f1f1;
      border-bottom: 1px solid #999;

      h2 { font-size: 32px; line-height: 38px; margin-bottom: 30px; }
      h3 { margin-bottom: 10px; color: #333; }

      p:last-child { margin-bottom: 40px; }

      div.description {
        text-align: left;
      }

      div.button-wrapper {
        margin-bottom: 40px;
        a { font-size: 18px;  }
        a.button {
          margin-bottom: 0;
          padding: 10px 25px;
          border-radius: 4px;
          color: #fff;
        }
      }

      div.speaker {
        text-align: center;
      }

    }

}

div.hybrid-info {
  text-align: center;

  h3 {
    margin-bottom: 20px;
  }

  div.vaccination {
    max-width: 700px;
    display: inline-block;

    h4 { font-weight: bold; font-size: 22px;}

    ul {
      list-style: none;
      display: inline-block;
    }

  }

}


/* div.past-show-message { margin-top: 100px; } */


/* main containers */

div.container.main { padding-bottom: 40px; min-height: 600px;  }
div.container.intro { padding-bottom: 40px; min-height: 300px;  }

div.container.suadmin { background: #fff; border: 1px solid #999; border-radius: 5px; margin-bottom: 18px; padding: 0 15px; }
div.container.speaker { background: #fff; border: 1px solid #999; border-radius: 5px; margin-bottom: 18px; padding: 0 15px; }

div.level { padding: 40px 0; }
div.layer { text-align: center; padding: 30px 0; }


table.wwFormTable td { padding: 5px; }


/* home */

div.home div.speakers a.bio-image { float: left; margin: 0 10px 0 0; }
div.home div.speakers h4 { font-size: 1em; }
div.home div.speakers h5 { font-size: 0.9em; font-weight: normal; }


div.featured-speakers {
  color: #fff; text-align: center;
  h2 { color: #fff; margin-bottom: 20px; }
  h4 { font-size: 1.14285714em; margin-bottom: 5px; }
  h5 { font-size: 0.85714286em; font-style: italic; color: #fff; }
  a { color: #fff; }
  img { margin-bottom: 18px;  }


  @media only screen and (max-width: 990px) {
    div.speaker {
      h4 { font-size: 16px; }
      img { width: 100px; height: 100px; }
    }
  }

  @media only screen and (max-width: 500px) {
    div.speaker {
      h4 { font-size: 14px; }
      img { width: 90px; height: 90px; }
    }
  }


}


div.contact-bar {
    border-top: 1px solid #999; background: #f1f1f1; padding: 10px 0;

    div.email {

       label { display: none; }
       input { margin-bottom: 5px; display: inline-block; }
       input.first-name { max-width: 100px; }
       input.last-name { max-width: 120px; }
       input.email { max-width: 180px; }
       button { margin-top: -3px; background: #333; color: #fff; }
    }

    div.buttons a { display: block; float: left; width: 61px; height: 60px; margin-left: 10px;  }

}

/* container */

div.container.contact {
    div.sponsors {
        h3 { margin-top: 0; }
        a { display: block; float: left; margin-right: 20px; }
        a.gradleware { background: url('rwx2014/sponsor_gradleware.png') no-repeat; width: 207px; height: 36px; }
        a.contegix { background: url('rwx2014/sponsor_contegix.png') no-repeat; width: 200px; height: 36px; }
        a.github { background: url('rwx2014/sponsor_github.png') no-repeat; width: 102px; height: 36px; }

    }


}

div.container.contact div.connect-buttons { margin-top: 30px; }
div.container.contact div.connect-buttons a { display: block; height: 60px; width: 60px; float: left; margin: 0 10px 0 0; }


/* coming soon */


div.showComingSoonContact { margin: 0 auto; }
div.showComingSoonContact h3 { background: #0072BB; }

/* schedule */

div.schedule div.day { margin: 0 0 30px 0; }
div.schedule div.day div.row { margin: 0 0 1px 0; }

div.schedule div.day div.row.break div.slot {  }

div.schedule h3 { font-family: 'Helvetica Neue', Helvetica, sans-serif; font-weight: normal; font-size: 1.4em; }
div.schedule h4 { font-size: 1em; }

div.schedule div.slot h4 { margin: 10px 0; }
div.schedule div.slot h4.time { float: left; padding-right: 10px; }
div.schedule div.slot h4.slot-label { float: left; }


div.schedule div.break-slot { background: url('rwx2014/bg_transparent_gray.png'); color: #333; }
div.schedule div.break-slot { border-radius: 4px; }
div.schedule div.break-slot div h4 { font-weight: normal; border-radius: 2px; }

div.schedule div.presentation {  height: 60px; }
div.schedule div.presentation h4 { font-size: 1em; font-weight: normal; margin: 0 0 5px 0; }
div.schedule div.presentation h4 { color: #333; }
div.schedule div.presentation h5 { font-size: 0.9em; font-weight: normal; margin: 0 0 2px 10px; }


div.schedule div.panel-collapse { padding: 0;margin: 0 0 3px 0; }

div.show-item-box { background: url('rwx2014/bg_transparent_layer.png'); }
div.show-item-box { border: 1px solid #fff; padding: 5px; }
div.show-item-box { margin: 0 0 1px 0; overflow: hidden;  border-radius: 4px; }

div.show-item-box h3 { font-size: 1.07em; margin: 0 0 5px 0; font-weight: normal;  }
div.show-item-box h4 { font-size: 1em; font-weight: normal; margin: 0 0 5px 10px; }
div.show-item-box h5 { font-size: 0.8571428571em; font-weight: normal; margin: 0 0 5px 10px; }


div.show-item-box:hover { cursor: pointer; background: url('rwx2014/bg_transparent_highlight.png'); }
div.show-item-box.active { background: url('rwx2014/bg_transparent_highlight.png'); border: 0; }

div.show-item-box a.image { display: block; margin: 0 10px 10px 0; float: left; }
div.show-item-box a.image img { border-radius: 4px; }

div.show-item-box div.arrow { float: right; display: block; width: 16px; height: 8px; margin: 4px 4px 0 0; }
div.show-item-box div.arrow { background: url('uber2015/uber_sprite_2015_3.png') no-repeat -16px 0;  }
div.show-item-box.active div.arrow { background: url('uber2015/uber_sprite_2015_3.png') no-repeat 0 0;  }

div.show-item-detail { background: #fff; padding: 5px; border: 1px solid #999; margin: 0; }
div.show-item-detail { border-radius: 4px; }
div.show-item-detail h3 { margin: 0 0 3px 0; color: #000; }
div.show-item-detail a.speaker { float: left; margin: 0 5px 5px 0; }
div.show-item-detail a.speaker img { border-radius: 3px; }
div.show-item-detail div.bio h4 { display: inline; color: #333;  }
div.show-item-detail div.bio em { font-size: 0.9em; }
div.show-item-detail div.slides { float: right; width: 45px; }
div.show-item-detail div.slides a { display: block;  }
div.show-item-detail div.slides img { width: 35px; }
div.show-item-detail ul { margin: 0 0 10px 20px; }


div.speaker-list div.image { float: left; margin: 0 5px 5px 0; }
div.speaker-list div.image img { max-width: 100px; border-radius: 4px; margin: 0 5px 5px 0; }
div.speaker-list div.item h3 { margin: 0 0 5px 0; font-size: 1.2em; }
div.speaker-list div.item h4 { color: #000; font-style: italic; font-size: 1em; font-weight: normal; margin: 0 0 0 10px; }

div.speaker-list div.detail-wrapper { padding: 0; }
div.speaker-list div.detail { background: #fff; border: 1px solid #999; border-radius: 4px; padding: 5px; }
div.speaker-list div.detail a.twitter { display: block; width: 30px; height: 24px; float: right; margin: 0 0 5px 5px;  }
div.speaker-list div.detail a.twitter { background: url('uber2015/uber_sprite_2015_3.png') no-repeat -32px 0; }
div.speaker-list div.detail div.presentations { margin: 0 0 20px 15px; }
div.speaker-list div.detail div.presentations h3 { font-weight: normal; font-size: 1.14em; color: #333; }
div.speaker-list div.detail div.presentation h4 { font-weight: normal; font-size: 1em; }
div.speaker-list div.detail a.profile { display: block; margin: 0 auto 10px auto; max-width: 200px; }




div.workshop-list-page {

    h1 { margin-bottom: 10px; }
    p.workshop-price {
      margin-bottom: 30px;
      max-width: 800px;
    }

    div.row { margin-bottom: 30px; }

    h3 { font-size: 18px; }

    div.image {
        text-align: center;
        h4 { font-size: 12px; }
    }

    a.requirements { color: #CC0000; float: right; display: block; margin: 10px 10px 0 0; }


}

div.session-detail {

    div.abstract {
        margin-bottom: 30px;
        max-width: 700px;
    }

    div.aboutSpeaker a.speaker-md { float: left; margin: 0 10px 10px 0; }

    div.workshop-requirements {
      margin-bottom: 30px;
      h3 { color: #CC0000; }

    }

}

div.workshop-requirements-page {

    ul.list { margin-bottom: 30px; }

    div.requirements { margin-bottom: 30px; }

}

div.how-can-we-help ul { margin: 0 0 20px 30px; }
div.how-can-we-help a { text-decoration: underline; }

div.travel-page {
  margin: 10px 0 0 0;
  img.hotel { border-radius: 8px; max-width: 100%; }
  div.row { margin-bottom: 28px; }
  em.expire { color: #cc0000; }
}


/* registration */

div.special-registration-page {
    p { margin-bottom: 28px; }
    table.itemTable { width: 100%; }

    div.sold-out {
        text-align: center;
        margin: 30px;
        h2 { color: #CC0000; }
    }
}


form#proceed-to-register input { display: block; margin: 30px auto; }

div.speaker-view {

  div.intro {
    h2 { margin-left: 20px; }
  }

  div.book {
    margin-bottom: 30px;
    h4 { font-size: 1.1em; }
    h4 div.termDef { width: 550px; overflow: hidden; }
    h4 div.termValue { font-size: 1em; }
    div.image { width: 100px; float: left; }
    div.image img { width: 100px;  }
    div.abstract {  width: 570px; float: left; margin: 0 0 0 20px; }
  }

  @media only screen and (max-width: 767px) {
      div.image {
          div.speaker-circle-md { margin: 0 auto; }
      }
  }

}

div.show-speaker-preview {
  text-align: center;
  margin-bottom: 30px;

  video {
    display: inline-block;
    //margin: 0 auto 10px auto;
    width: 400px;
    height: 300px;
  }
}

div.speaker-view, div.speaker-view-page {
  div.presentations-long {
      margin-bottom: 40px;

      div.presentation {
          margin-bottom: 30px;
          h3 { font-size: 18px; }
          h4 { font-size: 14px; }
      }
  }



}

div.video-preview-page {

  h1 { margin-bottom: 30px; }

  a.speaker-video {
    background: url('images/video_preview_lg.png') no-repeat;
    width: 130px; height: 130px; display: block; padding: 25px 0 0 25px;
    margin: 0 auto 20px auto;

    div.mask { width: 80px; height: 80px; overflow: hidden; }
    img { width: 85px; height: 85px; }
  }


  div.abstract {
    margin: 10px 0 30px 0;
    h3 {  font-size: 1.125em; }
    h4 {  font-size: 0.875em; }
  }

  @media only screen and (max-width: 767px) {
      div.abstract { text-align: center; }
      a.speaker-video { margin-bottom: 0; }
  }

}


body.show-view {

    h1 { margin-bottom: 40px; }

    div.session-list {

        div.description { margin-bottom: 40px; }
      
        div.speaker {

          margin-bottom: 40px;

          div.image { text-align: center; }

          div.topics {

            ul  {
              list-style: none;
              margin: 0; padding: 0;
              li { margin-bottom: 10px; }
            }

          }

        }


    }
}

div.workshop-requirements-page {

    h2 { margin-bottom: 30px; }

    div.summary {
        margin-bottom: 20px;
        h3 { font-size: 18px; }
        h4 { font-size: 14px; margin-left: 20px; }
    }

    div.requirements {
        margin-bottom: 30px;
    }

}

div.youtube-view-page {

    div.speaker {
        text-align: center;
        h3 { font-size: 16px; }
        h4 { font-size: 14px; }
    }
}

form.speaker-request {




}



div.register-early {

    div.register-proceed {
        padding: 30px 0;
    }

    div.note {
        margin-bottom: 30px;
    }

}

div.show-flickr-page {

  div.photoset {
    margin-bottom: 100px;
  }

  a.flickr-img {
    width: 240px; display: block; margin: 0 auto 15px auto;
    img { max-width: 100%; border: 1px solid #999; }
  }
}

div.show-special-raffle-page {

  h3.schedule {
    text-decoration: underline;
    text-align: center;
    font-size: 30px;
      a { color: #cc0000; }
  }

  div.speakers {
    h3 { font-size: 24px; margin-bottom: 30px; text-align: center; }

    div.speaker {
      height: 230px; overflow: hidden;
      text-align: center;
      margin-bottom: 30px;
      h5 { font-size: 12px; }
    }
  }
}

div.email-event-notifications {

  h3 {
    margin: 50px 0;
    text-align: center;
    a { color: #CC0000; font-size: 30px; text-decoration: underline; }
  }
}

div.order-update-app {

  div.order-info {
    font-size: 12px;
    margin-bottom: 40px;
    background: #f1f1f1;
    border: 1px solid #999;
    border-radius: 8px;
    padding: 5px;
    th { padding-right: 5px; }
  }


}

div.show-attendance-survey {

  max-width: 700px;
  margin: 0 auto;

  .header {
    text-align: center;
    margin-bottom: 30px;

    h1 { font-size: 32px; margin-bottom: 10px; }
  }

  h3 { margin-bottom: 20px; }

  div.radio { margin-left: 30px; }

  form {
     margin-bottom: 40px;
  }


}

div.contact-us-page {

  a.send-question {
    display: inline-block;
    margin-top: 30px; 
    padding: 10px 25px;
    border: 2px solid #3E82F7;
    border-radius: 4px;
    background: rgba(76, 144, 245, 0.1);
  }

  a.send-question:hover {
    background: rgba(76, 144, 245, 0.9);
    color: #fff;
    text-decoration: none;
  }

}

div.session-audio-list {

  h1 { margin-bottom: 30px; }

  p { margin-bottom: 30px; }

  div.day {
     margin-bottom: 40px;

    div.timeslot {
      h4 { margin-bottom: 30px; background: #F1f1f1; color: #000; padding: 5px 25px; border: 1px solid #999; }

      .presentation {
        display: inline-block;
        width: 150px;
        height: 150px;
        overflow: hidden;
        border: 1px solid #999;
        padding: 5px;
        h5 { font-size: 14px; margin-bottom: 5px; }
        em { font-size: 12px; }

      }
      a.presentation {
          h5, em { color: orange; }
      }
      a.presentation:hover {
        background: #FAFAD2;
      }

    }


  }
  
}



div.free-workshop {

  h1, h2 { text-align: center; }
  h1 { font-size: 32px; line-height: 38px; }
  h2 { font-size: 20px; }

  div.top-speaker {
    text-align: center;

    div.speaker {
      display: inline-block;
    }

  }

  ul.basicinfo {
    list-style: none;
  }

  div.signup {
    max-width: 700px;
    margin: 0 auto 40px auto;
  }


  div.speakers {
    margin-bottom: 40px;
    div.speaker {
      h5 { margin-bottom: 20px; }
    }

  }



}


