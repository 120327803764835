$orange: #E86D1F;

div.subscribe-form {
  max-width: 400px;
  //margin: 0 auto 0 auto;
  background: #fff;
  border: 1px solid #999;
  border-radius: 4px;
  padding: 15px;
  color: #000;
  margin-bottom: 20px;

  h3 { color: #000; margin-bottom: 5px; }

  input, label {
    display: block;
  }

  input[type='email'] {
    border-radius: 4px;
    //border: 0;
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
  }
  input[type='submit'] {
    background: $orange;
    color: yellow;
    padding: 3px 15px;
    border: 0;
    border-radius: 4px;
  }
  input[type='submit']:hover {
    color: #fff;
  }

} // subscribe
