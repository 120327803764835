



@use "menu-2015";

h1, h2, h3, h4, h5 { margin: 0 0 10px 0; padding: 0; font-weight: 500; line-height: 1.1; }
h5 { font-weight: normal; }

h3 { font-size: 16px;  }
h4, h5, h6 { font-size: 14px; }


ul { margin: 0; padding: 0; }
p { margin: 0 0 10px 0; }

.btn { margin-right: 10px; }
.btn:last-child { margin-right: 0; }

.btn-group {
  .btn { margin-right: 0; }
}


div.container {
  /* max-width: 1000px; */
  padding-left: 12px; padding-right: 12px;
}
div.container.body { min-height: 600px; padding-bottom: 28px; padding-top: 40px; }
div.container ul { margin-left: 20px; }


@media only screen and (max-width: 960px) {
  div.admin-links { display: none; }

  #top-nav { float: left; margin-left: 15px; }
  #top-nav li.alt-home { display: block; }
  #top-nav li:hover { background: #fff; }

}

/* narrow */

@media only screen and (max-width: 766px) {

  div.top-wrapper {

    ul#top-nav {
      display: block;
      width: 100%;
      float: none;
    }
  }

}

div#password-expired {
  text-align: center;
  background: #fff;
  color: #333;
  border-bottom: 1px solid #999;
  a { color: #cc0000; text-decoration: underline; }
}


div.admin-links {
  position: relative; z-index: 2;
  background: #f1f1f1; color: #333;


  .navbar-default {
    background: 0;
    .navbar-nav>li>a { color: #333; padding: 5px 10px; }
    .navbar-nav>li>a:hover { color: #EF8F1C;  }
  }

  div.rolenav {

      font-size: 14px;

      h4 { display: inline-block;

        padding: 6px; color: #333; margin: 2px 0 2px 0;
      }
      ul {
        display: inline-block;
        list-style: none;
        li {
            display: inline-block; padding: 0;
           a {
             padding: 2px 8px;
           }
        }

      }
  }

}


ul.hlink a { font-weight: normal; }

div.rolenav:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  width: 0;
  visibility: hidden;
}


h3 { margin-top: 0; margin-bottom: 5px; font-size: 1.14285em; }

div.navbar.top {

  position: relative; top: -1; z-index: 1; margin-bottom: 0;
  margin-top: -1px; border-bottom: 1px solid #fff;

  a {
    font-family: 'HelveticaNeue-Light', Helvetica, sans-serif;
    color: #fff; font-size: 1.14285714em; text-shadow: none;
    padding: 12px 10px 10px 10px;
  }

  span.icon-bar { background-color: #fff; }

  .navbar-toggle:focus, div.navbar.top .navbar-toggle:hover { background: #FFF; }
  .navbar-toggle:focus span.icon-bar, div.navbar.top .navbar-toggle:hover span.icon-bar { background-color: #0072BB; }

  li.dropdown ul li a {
    padding: 5px 10px; font-size: 1.142857em;
  }

}

div.navbar {

  border-radius: 0; border: none;

  div.date-and-location { font-size: 1.14285714em;color: #fff; float: left; max-width: 300px; padding: 12px 0 0 3px; }
  ul.nav  li  a { color: #fff; }

  .navbar-collapse { padding: 0; }

}

div.rolenav {
  nav { padding: 0; min-height: 0; }
  nav ul.nav li a { padding: 5px 15px; }
}

div.rolenav.speaker nav ul.nav li a { font-size: 0.92857143em;padding: 5px 7px; }

div.container.main div.rolenav h3 { margin: 0; color: #000; font-size: 14px; font-family: 'Helvetica Neue', Helvetica, sans-serif; }

nav.role-speaker { padding: 0; min-height: 0; }
nav.role-speaker ul.nav li a { padding: 5px 10px; }

div.role-link-holder {

  a {
    display: block;
    padding: 3px 10px;
    color: #fff;
    margin: 2px 0;
    border: 1px solid #fff;
    border-radius: 5px;
    background: rgba( 255, 255, 255, 0.6 );
    font-size: 16px;
  }
}

div.speaker-functions {
  margin-bottom: 30px;
  a.active { background: rgba(255, 147,	0, 0.2); }
}


/* staff and memeber top links */

a.speaker-md { width: 109px; height: 115px; display: block; background: url('images/speaker_bg.png') no-repeat; padding: 13px 0 0 12px; text-align: left; }
a.speaker-md { box-sizing: content-box; }
a.speaker-md img { width: 98px; height: 98px; }

a.speaker-sm { width: 60px; height: 64px; float: left; background: url('images/speaker_sm_bg.png') no-repeat; padding: 8px 0 0 8px; }
a.speaker-sm img { width: 54px; height: 54px; }

.speaker-circle-md {

  img {
    display: block;
    width: 130px; height: 130px;
    border-radius: 100px;
    border: 3px solid #999;
    margin: 0 auto 10px auto;
  }
}

.speaker-circle-sm {

  img {
    display: block; width: 54px; height: 54px;
    border-radius: 54px;
    border: 3px solid #999;
    margin: 0 auto;
  }
}

div.speaker-bio-view {
  margin-bottom: 30px;

  div.image {
    text-align: center;
  }
}

div.speaker-link-group {

  display: inline-block;

  a { display: inline-block; width: 30px; height: 30px; margin-right: 10px; text-decoration: none; }
  a:last-child { margin-right: 0; }

  /*
  a.twitter {  background: url($sprite) no-repeat -32px 0; }
  a.github {  background: url($sprite) no-repeat -361px 0; }
  a.web {  background: url($sprite) no-repeat -394px 0; }
  */

}

a.twitter-small {
  display: block; width: 30px; height: 30px; margin: 10px auto 30px auto;
  /*
  background: url($sprite) no-repeat -32px 0;
  */
}

/* footer */

footer {
  color: #fff;
  //text-align: center;
  text-align: left;
  padding: 30px 0 30px 0;

  a { color: #FFF; }
  a:hover { color: #EF9411; }
  ul.links { list-style: none; text-align: center; margin: 0 0 10px 0; }
  ul.links li { display: inline;  font-size: 0.9em; border-right: 1px dotted #999; padding: 2px 5px 2px 5px; }
  ul.links li.last { border-right: 0; }
  ul.links.sites {}

  div.copyright { text-align: center; }

  a.logo {
    svg { width: 150px; }
  }

}

body.no-nav {
  div.main-content {
    min-height: 700px;
  }
}


/* suadmin / speaker area // ARCHCONF - should apply to all?? */

div.container.speaker.main, div.container.suadmin.main {
  margin-top: 0;
  margin-bottom: 100px;
  border: 0;
  font-size: 14px;
}

body.suadmin.scheduler div.container.suadmin.main {
  margin-top: 40px;
}

body.speaker-app {
  div.top-wrapper {
    a.home-link { font-size: 30px; display: block;  }
  }

  footer { text-align: center; }


}

body.role-speaker div.speaker-app-wrapper {  padding-top: 50px;  }
body.role-admin div.speaker-app-wrapper {  padding-top: 80px;  }

body.tickets div.app-wrapper { min-height: 550px; }

div.order-admin-detail {
   div.section { margin-bottom: 60px; }
   h3 { font-size: 16px; }
}

#jp_container_1 .jp-controls-holder {
  width: 400px;
}

.jp-audio.nfjs .jp-type-single .jp-progress {
  width: 146px;
}

#modal-alert {
  display: none;

  position: fixed;

  bottom: 30px;
  right: 30px;
  width: 400px;
  z-index: 1000;


  background: #fff;
  border: 2px solid rgba(255, 0, 0, 1);
  border-radius: 8px;

  div.modal-content {
    background: rgba(255, 0, 0, 0.2);
    padding: 10px;
    font-size: 14px;
  }

  i { position: absolute; top: 5px; right: 5px; }
  i:hover { cursor: pointer; }

  h3 { color: #cc0000; text-align: center; }


}

@media only screen and (max-width: 600px) {

  #modal-alert {
    width: 300px;
    right: 10px;
  }

}