
/* The ribbons
 * https://codepen.io/eode9/pen/twkKm
 */

div.corner-ribbon-wrapper {
   position: relative;
   top: 0;
   right: 0;
   
}

.corner-ribbon{
  width: 100px;
  background: #e43;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 22px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  font-size: 10px;
}

.corner-ribbon.shadow{
  box-shadow: 0 0 3px rgba(0,0,0,.3);
}

/* Different positions */

.corner-ribbon.top-right{
  top: 10px;
  right: -85px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


/* Colors */


.corner-ribbon.red{background: rgba(239, 67, 52, 0.9);}
.corner-ribbon.orange{background: rgba(247,	141, 29, 0.9);}
.corner-ribbon.blue{background: rgba(24, 99, 162, 0.9);}
