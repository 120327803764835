
@use "site-2015";

$sprite: 'arch/arch_sprite_2015_2.png';

//$blue: #0072BB;
$blue: #015A82;
$orange: #E86D1F;
$orange2: #e9a32c;

$dark-blue: #003B5D;
$highlight-color: #03B2D5;
$grey-bg: #F3F3F3;
$box-border: #DCDCDC;

$footer-color: #333;

$mobile-size: 578px;




//$header-font-family: 'Montserrat', Helvetica, Arial, sans-serif;
//$body-font-family: 'Rubik', Helvetica, Arial, sans-serif;
$body-font-family: 'Open-Sans', Helvetica, Arial, Sans-Serif;
$header-font-family: 'Open-Sans', Helvetica, Arial, Sans-Serif;

//@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Rubik&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans&family=Roboto&display=swap');

body {
  //background: #fff;
  background: #5F5F5F;
  font-family: $body-font-family;
}

div.body-wrapper {
  //max-width: 1500px;
  background: #fff;
  margin: 0 auto;
}


a { color: $blue; }
a:hover { color: $orange; }

h1, h2, h3, h4 { font-family: $header-font-family; }

h1 { color: $blue; }



#nav-bar {

  padding: 10px 0;
  background: #000;

  a.home {
    display: block;
    float: left;

    font-size: 32px;
    padding: 10px 0 0 0;
    color: #fff;
    span {
      color: $orange;
    }
  }
  a.home:hover {
    text-decoration: none;
  }

  a.home-link { display: none; }

  #nfjs-drop-down-button {
    //path { fill: #000; }
    path { fill: #fff; }
  }

  div.nav-links {
    //display: block;
    float: right;
  }

  .nav-links > ul {
    margin-top: 10px;
    margin-bottom: 5px;

    li {
      text-transform: none;
      a { color: #fff; }
      a:hover { color: $orange; }
    }

    li.nfjs-dropdown {

      ul.nfjs-dropdown-menu {

        li {
          a { color: #000; }
          a:hover { color: $orange; }
        }
      }

    }

    li.register {

      a {
        padding: 10px 25px;
        background: $orange;
        border-radius: 4px;
      }
      a:hover {  color: #fff; }

      @media only screen and (max-width: 993px) {
        a { background-color: transparent; padding: 5px 10px; }
        //a { color: #000; }
        a:hover { color: $orange; }
      }

    }

  }

  .nav-links.show {

    ul>li>a { color: #000; }
    ul>li>a:hover { color: $orange; }

    @media only screen and (max-width: 993px) {
      border-bottom: 0;
    }
  }



  @media only screen and (max-width: 1200px) {
    .nav-links>ul>li>a {
      padding: 5px 10px;
    }
  }

  @media only screen and (max-width: $mobile-size) {

    .nav-links > ul {
      li {
        a { color: #000; }
        a:hover { color: $orange; }
      }

      li.register {
        a {
          border: 0; background: transparent;
          padding: 5px;
        }
      }

    }
  }

}


.navbar-speaker {
  background-color: $blue;
}

table.itemTable th { background-color: $blue; color: #fff; }
table.itemTable tr:nth-child(odd) {
  background-color: #f3f9fd;
}


/* footer */

footer {
  background: #000;
  color: $orange;
  text-align: left;
  //color: #fff;

  h4 { color: $orange; }
  a { color: $orange; }
  a:hover { color: #fff; }

  ul {
    list-style: none;
    margin: 0 0 10px 0;
    padding: 0;
  }

  address {
    font-size: 12px;
    margin: 20px 0 0 0;
  }

}


$mobile-break: 991px;

/* custom navbar collapse   */




@media (max-width: $mobile-break) {

  .container { width: 100%; padding: 0 15px; }

  body.show-home {

    div.top-wrapper {

      div.navbar {
        border-bottom: 0;
        li.register {
          a {
            background: none;
            border: 0;
          }
        }
      }
    }

  }

  div.top-wrapper {
    .container { padding: 0; }
    .navbar-collapse { background: $blue;  }


    #top-nav>li.active>a {  color: #fff; }
    #top-nav>li.active>a:hover {  color: $orange; }

    .navbar-default .navbar-toggle .icon-bar { background-color: #333; }

    div.navbar.top {
      border-bottom: 1px solid #333;
      li.home { display: block; }
      a { padding: 5px 10px; }
      ul.nav>li>a { color: #fff; }
      ul.nav>li>a:hover { color: $orange; }
    }
  }



  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
    border-width: 0;
  }

  .navbar-toggle:hover {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255,0.1);

    ul.dropdown-menu {
      width: 100%;
    }

    #top-nav li:hover {
      background: $blue;
    }

  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    /*  margin-top: 7.5px; */
  }
  .navbar-nav>li {
    float: none;
  }

  div.rolenav .navbar-nav>li {
    float: left;
  }

  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }
}









