/* common.scss */


.clear	{ clear: both; font: 0px/0px Arial; }
.block { display: block; }
.filler { display: block; height: 100px; width: 100px; }
.hidden { display: none; }

.strike { text-decoration: line-through; }

.clearfix:after, .section:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  width: 0;
  visibility: hidden;
}

.clickable:hover {
  cursor: pointer;
}

.note { font: 12px "Courier New", Terminal, monospace; border: 1px solid #999;background: #ff6; padding: 2px 4px 2px 4px; color: #000; margin: 20px 0 0 0; }
.special { border: 1px solid #999;background: #ff6; padding: 2px 4px 2px 4px; color: #000; margin: 20px 0 20px 0; }

.bd { border: 1px solid #999; }

.sansH h3, .sansH h4, .sansH h5 { font-family: Arial, Helvetica, sans-serif; }

div.bookmark { float: right; font-size: 0.9em; display:block; margin: 5px 20px 5px 0; }

ul.plain {
  list-style: none; margin-left: 0; padding-left: 0;
  li { clear: left; margin: 3px 0 0 0; padding: 0; }
}

ul.hlink	{
  float: right;  list-style: none; margin: 5px 5px 10px 0; padding: 0;
  li {  display: inline; margin: 0; padding: 0 10px; border-right: 1px dotted #336699;}
  li.last { padding: 0 0 0 10px; border-right: 0; }
  li:last-child { padding: 0 0 0 10px; border-right: 0; }
  a { font-weight: bold; }
}

a.hlink { font-weight: bold;  float: right; margin: 5px 5px 10px 0;}

ul.links {
  list-style: none; margin: 0; padding: 0;
  li { display: inline; margin: 0; padding: 2px 5px 2px 5px; border-right: 1px dotted #999; }
  li.last { border-right: 0; }
}

ul.hlink2 { float: right;  list-style: none; margin: 5px 5px 10px 0; padding: 0; font-size: 0.95em;
  li {  display: inline; margin: 0; padding: 0 10px; border-right: 1px dotted #336699;}
  li.last { padding: 0 0 0 10px; border-right: 0; }
  li:last-child { padding: 0 0 0 10px; border-right: 0; }
  a { font-weight: bold; }
}


ul.buttonlist	{ float:right; display: block; list-style: none; margin: 10px 20px 0 0; padding: 0; }
ul.buttonlist li { display:inline; margin: 0; padding-left: 3px;  }
ul.buttonlist form { display: inline; }

ul.buttonlist2	{ float:left; display: block; list-style: none; margin: 5px 0 0 0; padding: 0; }
ul.buttonlist2 li { display:inline; margin: 0; padding-left: 3px;  }

ul.inline { list-style: none; }
ul.inline li { display: inline; }


div.buttonlist { float:right; margin: 5px; display: block; }

div.buttonlist table { border-collapse: collapse !important; border: 0 !important; width: 1%; float: right;}

ul.fileList a { font-size: 8pt; }
ul.fileList { list-style: none; margin: 0 0 0 0; padding: 0; font-size: 8pt; }
ul.fileList li { clear: left; margin: 0 0 0 0; padding: 0; }

table.browseSlides ul.fileList span.type { font-size: 0.85em; }

.errorMessage { color: red;font-weight: bold; padding: 3px 0 3px 10px; display: block; }
.red { color: red; }

table.major { width: 98%; margin: 0 10px 0 10px; }

table.itemTable {
  margin: 10px 0 10px 0;border: 1px solid #999;border-spacing:0px;border-collapse:collapse;

  table, table.itemTable tr, table.itemTable td { border-spacing:0px;border-collapse:collapse; }

  th { text-align: left;font-size: 1.1em;font-weight: bold; padding: 3px; }
  th.select { width: 40px; }
  th.date { width: 100px; }
  th.longDate { width: 170px; }
  th.desc150 { width: 150px; }
  th.desc120 { width: 120px; }
  th.smallImage { width: 50px; }
  td.smallImage img { width: 50px; }
  th.label { white-space: nowrap; }
  th.icon { width: 20px; }
  th.rowNum { width: 20px; }
  td { text-align: left; }
  td.fileIcon { width: 25px; }
  td.fileSize { width: 80px; }
  td.fileDate { width: 120px; }
  th.sortable { font-size: 9pt;font-weight: bold; }

  tr { border-bottom: 1px dotted #999; }
  tr:nth-child(1) { border-bottom: 1px solid #999; }
  td { vertical-align: top;  padding: 3px; }
  td.rowNum { width: 20px; }
  td.nowrap { white-space: nowrap; }
  td.time { white-space: nowrap; width: 70px; }
  td.canceled { text-decoration: line-through; }
  tr.canceled { background: orange; }
  tr.empty { font-style: italic; }
  div.highlight { background: #F1F1F1; border: 1px solid #CFCFCF; margin: 5px;padding: 2px; }
  td.borderright { border-right: 2px solid #999; }
  th.borderright { border-right: 2px solid #999; }
  th.borderright2 { border-right: 1px dotted #999; }
  td.borderright2 { border-right: 1px dotted #999; }
  th.center { text-align: center; }
  tr.bordertop { border-top: 1px solid #999; }
  h4 { margin: 0; }

  td.label { color: #333; }

}

div.termDef { float:left; }
div.termDef input { margin-left: 20px; }
div.termValue { float: right; }
img.termValue { float: right; }
img.termDef { float: left; }
div.termValueLink { float: right; padding: 0 10px 10px 0; }

.tright { text-align: right; }
.tleft { text-align: left; }
.tcenter { text-align: center; }

.block { display: block; }

.w20  { width: 20px; }
.w30  { width: 30px; }
.w40  { width: 40px; }
.w50  { width: 50px; }
.w60  { width: 60px; }
.w70  { width: 70px; }
.w80  { width: 80px; }
.w100 { width: 100px; }
.w120 { width: 120px; }
.w125 { width: 125px; }
.w130 { width: 130px; }
.w140 { width: 140px; }
.w150 { width: 150px; }
.w160 { width: 160px; }
.w180 { width: 180px; }
.w200 { width: 200px; }
.w220 { width: 220px; }
.w230 { width: 230px; }
.w240 { width: 240px; }
.w250 { width: 250px; }
.w275 { width: 275px; }
.w300 { width: 300px; }
.w350 { width: 350px; }
.w375 { width: 375px; }
.w400 { width: 400px; }
.w480 { width: 480px; }
.w500 { width: 500px; }
.w550 { width: 550px; }
.w600 { width: 600px; }
.w650 { width: 650px; }
.w700 { width: 700px; }
.w750 { width: 750px; }
.w800 { width: 800px; }
.w900 { width: 900px; }

.h40 { height: 40px; }
.h50 { height: 50px; }
.h100 { height: 100px; }
.h200 { height: 200px; }
.h300 { height: 300px; }
.h360 { height: 360px; }
.h400 { height: 400px; }
.h500 { height: 500px; }

.ml0 { margin-left: 0 !important; }
.ml5 { margin-left: 5px !important; }
.ml10 { margin-left: 10px !important; }
.ml20 { margin-left: 20px !important; }
.ml30 { margin-left: 30px !important; }
.ml40 { margin-left: 40px !important; }
.ml50 { margin-left: 50px !important; }
.ml100 { margin-left: 100px !important; }
.ml150 { margin-left: 150px !important; }
.ml200 { margin-left: 200px !important; }
.ml250 { margin-left: 250px !important; }
.ml275 { margin-left: 275px !important; }
.ml300 { margin-left: 300px !important; }

.mt5 { margin-top: 5px !important; }
.mt10 { margin-top: 10px !important; }
.mt15 { margin-top: 15px !important; }
.mt20 { margin-top: 20px !important; }
.mt30 { margin-top: 30px !important; }
.mt40 { margin-top: 40px !important; }
.mt50 { margin-top: 50px !important; }
.mt60 { margin-top: 60px !important; }
.mt100 { margin-top: 100px !important; }
.mt200 { margin-top: 200px !important; }

.mr10 { margin-right: 10px !important; }
.mr20 { margin-right: 20px !important; }
.mr30 { margin-right: 30px !important; }
.mr50 { margin-right: 50px !important; }

.mb10 { margin-bottom: 10px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 40px; }
.mb50 { margin-bottom: 50px; }
.mb100 { margin-bottom: 100px; }
.mb200 { margin-bottom: 200px; }
.mb300 { margin-bottom: 300px; }

div.formWrapper.addsponsor { width: 300px; }
div.formWrapper.addsponsor h2 { margin: 0 0 15px 0; }
div.formWrapper.addsponsor label { display: block; float: left; width: 90px; }
div.formWrapper.addsponsor div.set { margin: 0 0 15px 0; }

#find-user { position: absolute; background-color: #FFF; border: 1px solid #999; padding: 10px; z-index: 100; }

table.wwFormTable td.tdLabel { vertical-align: text-top; }
table.wwFormTable span.errorMessage { text-align: left; }

table.wwFormTable th { font-weight: bold; text-align: left; }
table.wwFormTable td { text-align: left; }
table.wwFormTable { font-size: 1em; }
table.wwFormTable .label { color: #000; }

/*
div.tab-content {
  div:first-child { padding-top: 10px; }
}
*/

div.four-o-four {
   text-align: center;
  h1 { margin-top: 100px; }
  h3 { margin-bottom: 100px; }
}

/* feature set modal */

.modal {
  form {
    .modal-body { padding: 15px 30px; }
  }
}

.modal-backdrop.am-fade {
  opacity: .5;
  transition: opacity .15s linear;
  &.ng-enter {
    opacity: 0;
    &.ng-enter-active {
      opacity: .5;
    }
  }
  &.ng-leave {
    opacity: .5;
    &.ng-leave-active {
      opacity: 0;
    }
  }
}


/* spinner */

.spinner {
  height: 40px;
  width: 40px;
  margin: 94px auto 0 auto;
  position: relative;
  -webkit-animation: spinnerRotation .6s infinite linear;
  -moz-animation: spinnerRotation .6s infinite linear;
  -o-animation: spinnerRotation .6s infinite linear;
  animation: spinnerRotation .6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, .15);
  border-right: 6px solid rgba(0, 174, 239, .15);
  border-bottom: 6px solid rgba(0, 174, 239, .15);
  border-top: 6px solid rgba(0, 174, 239, .8);
  border-radius: 100%;
}

@-webkit-keyframes spinnerRotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes spinnerRotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes spinnerRotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes spinnerRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}