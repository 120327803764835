
#show-speakers-page {

    p.page-description {
        margin-bottom: 30px;
    }

    div.speaker {
      margin-bottom: 40px;
      text-align: center;
      height: 250px;
      overflow: hidden;

      a {
        display: block;
        margin-bottom: 10px;
      }


    }
}