$mobile-size: 993px;

//#top-nav { float: right; }
//#top-nav li.alt-home { display: none; }


#nav-bar {

  div.container {
    padding: 5px 0;
    ul { margin-left: 0; }
  }

  #nfjs-drop-down-button {
    display: none;
    padding: 5px;
    float: right;
  }

  .nav-links {
     padding: 5px 0;
  }

  .nav-links > ul {
    float: right;
    list-style: none;


    li {
      display: inline-block;
      padding: 0;
      text-transform: uppercase;

      a { padding: 5px 15px; text-decoration: none; }

      a#nfjs-menu-close {
        position: absolute;
        width: 25px; height: 25px;
        display: none;
        border-bottom: 0;
        top: 0;
        right: 30px;
        z-index: 2;
        svg, img { width: 25px; height: 25px; }
      }
      a#nfjs-menu-close:hover {
        cursor: pointer;
      }

    }

    li.nfjs-dropdown {
      position: static;

      ul.nfjs-dropdown-menu {
        margin: 0; padding: 0;
        display: none;
        background: #fff;
        position: absolute;
        border: 1px solid #999;
        //top: 0;
        //right: 0;

        z-index: 12;
        li {
          display: block;
          padding: 0; margin: 0;
          a {
            color: #333;
            display: block;
            padding: 5px 20px;
            border-bottom: 1px dotted #999;
          }
          a.hidden { display: none; }
          a:hover { background: #FAFAD2; color: orange; }
        }
        li:last-child {
          a { border-bottom: 0; }
        }
      }

    }

    li.nfjs-dropdown:hover > ul.nfjs-dropdown-menu {
      display: block;
    }



  } // nav-links




}

@media only screen and (max-width: $mobile-size) {

  #nav-bar {

    #nfjs-drop-down-button {
      display: block;
      margin-left: 5px;
    }
    #nfjs-drop-down-button:hover {
      cursor: pointer;
    }

    .nav-links {
      display: none;
      width: 100%;
      position: absolute;
      top: 0;left: 0;
      z-index: 2;
      padding: 0;
      border-bottom: 1px solid #999;
    }


    .nav-links > ul {
      float: none;
      width: 100%;
      margin: 0;

      li {
        display: block;
        width: 100%;
        background: #fff;
        a { display: block; color: #000; border-bottom: 1px solid #999; }

        a#nfjs-menu-close {
          display: block;
        }


      }

      li.nfjs-dropdown > ul.nfjs-dropdown-menu {
        position: relative;
        display: block;
        border: 0;
      }

    }

    .nav-links.show {
      display: block;
    }

  }


}

body.show-home {
  #nav-bar {
    //background: transparent;
  }
}