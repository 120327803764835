@use "theme-2015";
@use "site-arch" as site;

@use "subscribe-2024";

body.show-home, body.show-view {

  a { color: site.$orange2; }
  a:hover { color: #fff; }

  .orange-button {
    background: site.$orange;
    color: #fff;
    padding: 10px 25px;
    border-radius: 4px;
  }
  .orange-button:hover {
    color: yellow;
    text-decoration: none;
  }

  div.body-wrapper {
    background: #000;
    color: #fff;
  }

  div.level {
    padding: 60px 0 60px 0;
    text-align: left;
    h3 {
      font-size: 28px;
      margin-bottom: 20px;
    }
    p { font-size: 20px; }
  }

  @media (max-width: 540px) {
    div.level {
      padding: 30px 0 30px 0;
      h3 { font-size: 22px; }
    }
  }

}



.speaker-circle-sm, .speaker-circle-md {
  img { border-color: site.$orange; }
}


body.show-home {

  div.intro {

    div.left-side {
      text-align: center;

      img {
        margin-bottom: 20px;
      }

      h1 {
        font-size: 64px;
        color: #fff;
        font-family: Roboto, 'Open Sans', Sans-Serif;
      }
      h2.tag {
        color: site.$orange2;
        font-size: 32px;
      }
      h3.date-and-location {
        font-size: 28px;
      }

      h4.past-event {
        font-size: 18px;
        color: yellow;
      }

      p.early-bird {
        color: site.$orange2;
        margin-bottom: 30px;
      }


    }

    div.right-side {
      padding-top: 60px;

      div.discount-widget {
        //background: #71c1fa;
        border-radius: 4px;
        border: 1px solid site.$orange;
        padding: 10px;
        color: #fff;
        //max-width: 330px;
        margin: 0 auto 40px auto;
        text-align: center;

        span { color: yellow; }

        h4 { text-align: center; font-size: 18px; color: site.$orange; font-weight: bold; }
        p { margin-bottom: 5px; }
        p:last-child { margin-bottom: 0; }

        a { color: site.$orange; }

        p.credit {
          color: #cc0000;
        }
        p.three-free {
          color: yellow;
        }

      }

      div.triple {
        border-radius: 4px;
        border: 1px solid site.$orange;
        padding: 10px;
        color: #fff;
        margin: 0 auto 40px auto;
        text-align: center;

        h4 { text-align: center; font-size: 18px; color: site.$orange; font-weight: bold; }
        p { margin-bottom: 5px; }
        p:last-child { margin-bottom: 0; }

        a { color: yellow; }

        div.offer {
          text-align: left;
          h5 { font-size: 16px; color: yellow; }

        }
        a.orange-button {
          color: #fff;
          margin-bottom: 10px;
          display: inline-block;
        }


      }


    }
    
    @media (max-width: 540px) {
      div.left-side {
        img { max-width: 150px; }
        h1 { font-size: 48px; line-height: 48px; }
        h2.tag { font-size: 24px; line-height: 24px; }
        h3.date-and-location { font-size: 18px; }
      }
    }

    @media (max-width: 391px) {
      div.left-side {
        h2.tag { font-size: 18px; line-height: 18px; }
        h3.date-and-location { font-size: 16px; }
      }
    }

  } /* intro */




  div.tomorrow-resort {
    text-align: left;

    div.tomorrow {
      span {
        color: site.$orange;
      }
    }

    div.resort {
      text-align: center;
      h4 { color: site.$orange; font-size: 22px; }
      img {
        max-width: 100%;
        border: 1px solid site.$orange;
        border-radius: 4px;
        margin-bottom: 30px;
      }

      div.counter {
        text-align: center;
        color: site.$orange2;
        margin-bottom: 30px;
        //color: #3D8CBD;
        p.date-message {
          text-align: center;
        }

        table {
          display: inline-block;
          td {
            margin-right: 10px;
            //min-width: 85px;
          }
          td>div {
            padding: 3px;
            h4 {
              border: 1px solid site.$orange2;
              color: site.$orange2;
              font-size: 16px;
              padding: 5px;
              display: inline-block;
            }
          }
        }
      } /* counter */

      div.replay {
        h4 {
          font-size: 16px;
          margin-bottom: 15px;
        }
      }



    }

  } /* tomorrow-resort */

  div.subscribe-virtual {


    div.subscribe-form {
      border: 1px solid site.$orange;
      margin: 0 auto 30px auto;
      h3 { font-size: 18px; }
    }

    div.video-replay {
      display: block;
      //max-width: 300px;
      width: 100%;
      background: #fff;
      padding: 15px 10px;
      border: 1px solid site.$orange;
      border-radius: 4px;
      margin: 0 auto 30px auto;
      color: #000;
      text-align: center;
      h4 { font-size: 16px; font-weight: bold; margin-bottom: 5px; color: #000 !important; }
    }


    //div.virtual {
    //
    //  h4 { color: site.$orange2; font-size: 28px; }
    //  span { color: site.$orange2; }
    //
    //}

    div.virtual {

      h3 { margin-bottom: 10px; font-size: 24px; }
      h4 { color: site.$orange; font-size: 18px; font-weight: normal; margin-bottom: 30px; }

    } // virtual-credits


  } /* subscribe-virtual */

  div.topics {
      text-align: center;
  }

  div.three {
    text-align: center;

    i {
      color: site.$orange2;
      font-size: 32px;
    }

    svg {
      max-width: 40px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 18px;
      color: site.$orange2;
    }

    p {
      text-align: left;
    }

  }

  div.speakers.level {

    background: #fff;
    color: #000;

    h3 { color: #000; }

    div.speaker {
      text-align: center;
      margin-bottom: 10px;
      h4, h5 { text-weight: normal; }
      h4 { font-size: 16px; margin-bottom: 2px; }
      h5 { font-size: 14px; }
      .speaker-circle-md {
        //img {
        //  max-width: 100%;
        //}
      }
    }

  } // speakers

  div.workshops {

    div.workshop {
      display: table;
      margin-bottom: 10px;

      div.image {
        display: table-cell;
        width: 54px;
        img { max-width: 100%; }
      }
      div.about {
        display: table-cell;
        vertical-align: top;
        padding-left: 10px;
        //max-width: 330px;
      }
    }


  }

  div.travel {
    background: #fff;
    color: #000;
    border-bottom: 1px solid #999;

    div.image-wrapper {
      position: relative;
      img {
        width: 100%;
        border: 1px solid site.$orange;
        border-radius: 4px;
      }

      div.travel-deal {
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 1;
        max-width: 500px;
        padding: 20px;
        color: #fff;

        h3 {
          font-weight: 700;
          margin-bottom: 20px;
          font-size: 24px;
        }

        p { margin-bottom: 10px; }

        a.orange-button { color: #fff; }

      }

    }

    div.about {

      a { color: site.$blue; }

      a.pin {
        display: inline-block;
        margin-right: 10px;
        color: site.$orange;
        svg {
          width: 40px;
          display: block;
        }
      }

      address {
        display: inline-block;
        margin: 0;
      }

    } /* about */

    @media (max-width: 768px) {
      div.image-wrapper {
        margin-bottom: 20px;
      }
    }

  } /* travel */

  div.reasons {

    //background: site.$grey-bg;
    //background: site.$grey-bg;
    text-align: center;
    color: #fff;
    border-bottom: 1px solid #fff;

    div.idea {
      display: inline-block;
      width: 60px;
      height: 60px;
      margin-bottom: 30px;
      padding: 10px;
      background: site.$orange;
      border-radius: 60px;
      svg { margin-bottom: 0;
        path { fill: #fff !important; }
      }
    }

    h2 {
      background: url("/styles/2019/arch/reasons_bg.svg") no-repeat top center;
      padding: 20px;
      font-weight: 700;
      //color: #000;
    }

    ul {
      list-style: none;

      li {
        background: #fff;
        border-left: 10px solid site.$orange;
        padding: 5px 25px;
        margin-bottom: 20px;
        text-align: left;
        color: #000;
      }

    }

  } /* reasons */

  div.pdf-download {
    text-align: center;
    //background: site.$grey-bg;


    svg { width: 40px; display: block; margin: 0 auto 20px auto; }

    p { max-width: 700px; margin: 0 auto 20px auto; }

    a.share { font-size: 18px; text-decoration: underline; }

  }

  div.sponsors {
    background: #fff;
    color: #000;
    text-align: center;

    div.icon {
      svg {
        width: 40px;
      }
    }

    div.azul {
      img {
        max-width: 256px;
        max-height: 66px;
      }
    }
    div.moderne {
      padding-top: 8px;
    }
    div.codescene {
      padding-top: 8px;
    }


  } /* sponsors */

  div.past-events {

    border-bottom: 1px solid #fff;

    div.image {
      text-align: center;
      img { max-width: 100%; border: 2px solid site.$orange; border-radius: 4px; }
    }
    div.list {
      ul {
        list-style: none;
        //li a { color: #fff; }
        //li a:hover { color: site.$orange; }
      }
    }

  } /* past-events */

//}


} /* show-home */

body.show-view {
  color: #fff;
  svg { width: 40px; height: 40px; margin: 0 auto 20px auto; display: block; }

  h1 { color: #fff; }

  div.session-list {
    margin-bottom: 10px;

    div.speaker {
      margin-bottom: 30px;

      div.speaker-header {
        display: table;
        margin-bottom: 10px;

        a.speaker-circle {
          display: table-cell;
          width: 100px;

          img {
              width: 100px; height: 100px;
              border: 2px solid site.$orange;
              border-radius: 75px;
          }
        }
        div.bio {
          display: table-cell;
          padding-left: 10px;
          vertical-align: middle;
        }
      }

      ul {
        list-style: none;
        margin: 0;
      }

    }
  } /* session-list */

  div.speaker-list {

      div.speaker {
          margin-bottom: 20px;

          div.speaker-header {
              display: table;
              margin-bottom: 10px;

              a.speaker-circle {
                  display: table-cell;
                  width: 100px;

                  img {
                      width: 100px; height: 100px;
                      border: 2px solid site.$orange;
                      border-radius: 75px;
                  }
              }
              div.bio {
                  display: table-cell;
                  padding-left: 10px;
                  vertical-align: middle;
              }

          }


          div.full-bio {
              text-align: left;
              height: 250px;
              overflow: hidden;
              margin-bottom: 10px;
          }

      }
  } /* speaker-list */

    div.travel-information-page {


        div.opal-sands {
            margin-bottom: 40px;
            position: relative;

            img {
                max-width: 100%;
                border-radius: 4px;
                border: 1px solid site.$orange;
            }


            div.address-block {
                background: rgba(130, 130, 130, 0.6);
                position: absolute;
                bottom: 30px;
                left: 0;
                z-index: 1;
                color: #fff;
                padding: 25px 45px;
                text-transform: uppercase;
                font-weight: bold;

            }

        }

        @media only screen and (max-width: 768px) {
            div.opal-sands {
                div.address-block {
                    padding: 10px 15px;

                    h3 {
                        font-size: 14px;
                    }

                    h4 {
                        font-size: 14px;
                        margin-bottom: 0;
                    }
                }
            }
        }

        @media only screen and (max-width: site.$mobile-size) {
            h1 { font-size: 36px; }
            div.opal-sands {
                div.address-block {
                    position: static;
                }
            }
        }



    } /* travel-information-page */

    div.block {
        background: #fff;
        color: #000;
        border: 1px solid site.$orange;
        border-radius: 4px;
        padding: 20px;
        margin-bottom: 40px;
        h3 {
            margin-bottom: 10px;
            svg { width: 25px; height: 25px; margin-right: 5px; margin-bottom: 5px; display: inline-block; }
        }
    }

    div.hotel {
        a.book-now { color: #cc0000; text-decoration: underline; }
    }



}

/* 2024 sale */
body.show-view div.triple-play {

  background: #fff;
  color: #000;
  border-radius: 4px;
  h1 { color: #000; text-transform: uppercase; }



}

